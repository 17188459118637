export default class ArrayChunker {
    static chunk(originalArray, chunkSize) {
        if (chunkSize <= 0) {
            return [[]];
        }

        const chunkedArrays = [];

        for (let i = 0; i < originalArray.length; i += chunkSize) {
            const chunk = originalArray.slice(i, i + chunkSize);
            chunkedArrays.push(chunk);
        }

        return chunkedArrays;
    }
}
