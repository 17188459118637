<template>
  <b-card>
    <b-form-group
      label-for="is-media"
      label="A mensagem contém mídia?"
    >
      <b-form-checkbox
        id="is-media"
        v-model="isMediaMessage"
      />
    </b-form-group>

    <b-form-group
      label-for="receivers"
      label="Recebedores"
    >
      <multiselect
        v-model="receiversSelected"
        :options="receiversOptions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Selecione os Recebedores"
        select-label=""
        selected-label=""
        deselect-label=""
        label="name"
        track-by="name"
      >

        <span slot="noResult">
          Nenhum Recebedor Encontrado!
        </span>
      </multiselect>
    </b-form-group>

    <b-form v-if="!isMediaMessage">
      <b-form-group
        label="Conteúdo"
        label-for="content"
      >
        <b-form-input
          v-model="content"
          placeholder="Mensagem"
        />
      </b-form-group>

    </b-form>

    <b-form v-else>
      <b-form-group
        label="Conteúdo"
        label-for="content"
      >
        <b-form-file
          id="file"
          v-model="contentFile"
          placeholder="Arraste ou selecione um arquivo"
          drop-placeholder="Arraste o arquivo aqui."
          browse-text="Procurar"
        />

        <b-form-group
          class="mt-1"
          label="Legenda"

          label-for="caption"
        >
          <b-form-input
            v-model="content"
            placeholder="Legenda"
          />
        </b-form-group>
      </b-form-group>

    </b-form>

    <b-button
      variant="primary"
      @click="sendMessage"
    >
      Enviar
    </b-button>
  </b-card>
</template>

<script>
import {
    BForm, BFormGroup, BFormInput, BCard, BButton, BFormCheckbox, BFormFile,
} from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';

import AtomService from '@/service/atom';

import ArrayChunker from '@/utils/collection-chunker';

const formats = {
    image: ['jpeg', 'png', 'jpg'],
    document: ['pdf', 'xlsx', 'csv', 'xlsm'],
    audio: ['mp3'],
};

export default {
    components: {
        BButton,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        Multiselect,
        BFormCheckbox,
        BFormFile,
    },

    data() {
        return {
            isMediaMessage: false,
            content: '',
            receivers: [],
            receiversSelected: [],
            contentFile: null,
        };
    },

    computed: {
        receiversOptions() {
            return this.receivers.map(item => ({
                name: item.name,
                id: item.id,
            }));
        },
    },

    async mounted() {
        this.callLoading(true);
        const { data: { data: userData } } = await AtomService.getAllGroups();
        const { data: { data: groupData } } = await AtomService.getAllUsers();

        this.receivers = [...userData, ...groupData];
        this.callLoading(false);
    },

    methods: {
        async apiSendMessage() {
            await AtomService.sendMessage({
                receiversIds: this.receiversSelected.map(item => item.id),
                content: this.content,
            });
        },

        async apiSendMediaMessage() {
            const splitted = this.contentFile.name.split('.');
            const extension = splitted[splitted.length - 1];

            const type = Object.entries(formats).find(([_, value]) => value.includes(extension))[0];
            const chunked = ArrayChunker.chunk(this.receiversSelected, 15);
            // eslint-disable-next-line no-restricted-syntax
            for (const chunk of chunked) {
                // eslint-disable-next-line no-await-in-loop
                await Promise.all(chunk.map(item => AtomService.sendMediaMessage({
                    receiverId: item.id,
                    type,
                    content: this.contentFile,
                    caption: this.content,
                })));
            }
        },

        async sendMessage() {
            const response = await this.confirmAnAction('Deseja realmente enviar essa mensagem?');

            if (!response) return;

            this.callLoading(true);

            if (this.isMediaMessage) {
                await this.apiSendMediaMessage();
            } else {
                await this.apiSendMessage();
            }

            this.callLoading(false);

            this.modalSuccess('Mensagem enviada com sucesso');
        },
    },
};
</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #124680 !important;
}

.multiselect__tag {
    background: #124680 !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
